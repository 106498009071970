@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  font-weight: 400;
  /* font-family: "Poppins", sans-serif; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  line-height: 1.3;
  position: relative;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img {
  display: block;
  width: 100%;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1000px;
  padding: 0 20px;
}

.form-control {
  font-size: 16px;
  padding: 14px 14px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7E8B9E;
  border-radius: 10px;
}
.content::-webkit-scrollbar-thumb{
  background:  #3667E9;
}
.content::-webkit-scrollbar-track {
  background: #E1E1E1;
}
.content{
  overflow-x: hidden !important;
}
@media screen and (max-width: 1200px) {}


.microsoft-btn{
  background-image: url(./images/microsoft.png);
  background-size: 100px 27px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  width: 100%;
  height: 39px;
  border-radius: 5px;
  border: 1px solid rgb(231, 231, 231);
}

textarea:focus-visible{
  outline: none;
}
.or{
  display: flex;
  align-items: center;
  margin: 28px auto;
  &::before, &::after{
      content: '';
      width: calc(50% - 10px);
      height: 1.5px;
      background-color: rgb(204, 204, 204);
      margin: 2px;

  }
}