.toogleBtn{
  display: flex;
  justify-content: center;
  gap: 8px;
  $ht : 18px;
  $wt : 44px;
  align-items: center;
    .label {
        display: inline-block;
        font-size: 14px;
    }
        
    .toogle-display {
        transition: 0.3s;
        cursor: pointer;
        background: #b0b3b7;
        border-radius: 30px;
        height: $ht;
        width : $wt;
    }
    .toogle-display:before {
      position: relative;
      content: "";
      display: block;
      height: calc($ht - 2px);
      width: calc($ht - 2px);
      left: 1px;
      bottom: -1px;
      background-color: #fff;
      border-radius: 50%;
      transition: 0.3s;
      }
      
      .toogle-display.checked {
        background-color: #004cff;
      }
      
      .toogle-display.checked:before {
        transform: translateX(calc($wt - $ht));
      }
              
}