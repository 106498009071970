.appointment-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 84px);
  background-color: #f5f6f6;
  padding: 20px;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.text-center {
  text-align: center;
}

button {
  text-transform: none;
}

button:active {
  box-shadow: none;
}

.form-label {
  font-weight: 500;
  color: #333;
}

.form-row {
  display: flex;
}

.calendar {
  border: solid #f5f6f6 2px;
  border-radius: 16px;
}
