.agenda{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: calc(100vh - 64px);
    // background-color: #f5f6f6;
    // padding: 20px 0px;
    // gap: 20px;
    flex-wrap: wrap;
   // overflow-y: scroll;
    // padding-right: 50px;

    .left{
        width: 100%;
       //min-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        max-width: 95%;
        background-color: #fff;
        border-radius: 12px;
        // padding: 0px 30px;
        overflow-x: scroll;
        position: relative;
        // border: 1px solid rgb(223, 223, 223);
       .head{
        position: sticky;
        // padding-top: 12px;
        top: 0px;
        background-color: #fff;
        font-weight: bold;
        font-size: 26px;
        z-index: 1;
        .today-date {
            font-size: 0.6em;  // Slightly smaller than before
            font-weight: 500;  // Semi-bold
            margin-left: 10px;  // Increased space between the label and the date
            color: #656565;  // A softer color for the date
        }

        @media (max-width: 1305px) {
            .today-date {
                display: block;
            }

        
          }
       .date{
        font-size: 18px;
        color: rgb(101, 101, 101);
        }
       }
        .list{
            margin-top: 12px;

            display: flex;
            flex-direction: column;
            gap: 8px;
            .item{
                border-radius: 4px;
                padding: 8px;
                cursor: pointer;
                line-height: 38px;
                align-items: center;
                display: flex;
                justify-content: space-around;
                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: #E0E0E0;
                    }
                }
                .seg1{
                    border-right: 3px solid #3667E9;
                    padding: 5px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                  
                    .time{
                        font-size: 12px;
                        color: gray;
                    }
                }

                .seg2{
                    width: calc(100% - 80px);
                   .top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name{
                        font-weight: 700;
                    }
                    .icon{
                        cursor: pointer;
                    }
                   
                   }

                   .dob{
                    font-weight: 500;
                    font-size: 14px;
                    color: gray;}

                   .desc{
                    font-size: 14px;
                    color: gray;
                }
                   
                }
            }
        }
       
    }
    .norecord{
        height: 100%;
        width: 100%;
        font-size: 22px;
        color: gray;
        justify-content: center;
        opacity: 0.7;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .right{
        max-width: 95%;
        height: min-content;
        width: min-content;
        background-color: #fff;
            border-radius: 16px;
        &>div{
           margin: 0px !important; 
        }
       }
}
.agenda-main{
    justify-content: left;
    .left .list .item{
        background-color: #F7F7F7;
    }
}

.admin-component{
    display: flex;
    justify-content: left;
    width: 100%;
    min-height: calc(100vh - 84px);
    background-color: #f5f6f6;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding-right: 50px;

    .left{
        width: calc(100% - 400px);
        min-width: 300px;

        padding: 0px 30px;
        border: 1px solid rgb(223, 223, 223);
       .head{
        padding-top: 12px;
       .date{
        font-size: 18px;
        color: rgb(101, 101, 101);
        }
       }
        .list{
            margin-top: 12px;

            display: flex;
            flex-direction: column;
            gap: 8px;
            .item{
                border-radius: 4px;
                padding: 8px;
                display: flex;
                line-height: 18px;
                justify-content: space-between;
                transition: all 200ms;
                cursor: pointer;
                width: 100%;
                &:hover{
                    background-color: #F7F7F7;
                }
      

                .seg2{
                    width: calc(100% - 80px);
                   .top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name{
                        font-weight: 700;
                    }
                    .icon{
                        cursor: pointer;
                    }
                    .time{
                        font-size: 12px;
                        color: gray;
                        line-height: 14px;
                    }
                   }

                   .desc{
                    font-size: 14px;
                    color: gray;
                }
                   
                }
            }
        }
       
    }
}

.overview-component{

    .left{
        width: 100%;
    }
    .overview-head{
        display: flex;
        justify-content: left;
        gap: 20px;

        .overview-item{
            font-size: 18px;
            font-weight: 500;
            color: #7F7F7F;
            padding: 8px;
            cursor: pointer;
            &.active{
                color: #3667E9;
                border-bottom: 3px solid #3667E9;
            }
        }
    }
}