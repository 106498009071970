.recording-details {
    background-color: #F5F6F6;
    height: calc(100vh - 84px);
    overflow-y: auto;
    position: relative;

    .detail-container {
        height: auto;
        min-height: calc(100% - 28px);
        border-radius: 8px;
        margin: 1vw 2vw;
        padding: 0px;
        background-color: #fff;
        overflow-y: visible;
        position: relative;

        .header-recording {
            padding: 20px;
            display: flex;
            width: 100%;
            box-sizing: border-box;
            border-radius: 12px 12px 0px 0px;
            justify-content: space-between;

            .name-box {
                .label {
                    font-weight: 700;
                    color: black;
                    font-size: 16px;
                }
                .value {
                    color: #7F7F7F;
                    font-size: 18px;
                }
            }

            .date-box {
                .date {
                    font-weight: 700;
                    color: black;
                    font-size: 16px;
                }
                .time {
                    color: #7F7F7F;
                    font-size: 14px;
                }
            }
        }

        .content {
            border-radius: 8px;
            background-color: #ECECEC;
            padding: 12px;
            box-sizing: border-box;
            width: 100%;

            .header-recording {
                font-weight: 700;
                font-size: 18px;
            }
            .text-box {
                border-radius: 6px;
                background-color: #fff;
                padding: 8px;
                color: #7F7F7F;
                font-size: 14px;
                height: 320px;
                overflow-y: auto;
                border: none;
                width: 100%;
                resize: none;
                &:focus-visible {
                    outline: none;
                }
                &::-webkit-scrollbar-thumb {
                    background: #3667E9;
                }
                &::-webkit-scrollbar-track {
                    background: #ffffff;
                }
            }
        }

        .footer {
            padding: 30px 20px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 20px;

            .box {
                .label {
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    color: black;
                    margin-bottom: 10px;
                }

                .text {
                    width: 120px;
                    border-radius: 2px;
                    border: 1px solid #DEDEDE;
                    color: #3667E9;
                    font-weight: 700;
                    height: 28px;
                    align-content: center;
                    box-sizing: border-box;
                    padding: 4px;

                    &:focus-visible {
                        outline: none;
                    }
                }
            }

            .submit-container {
                margin-left: auto;
            }

            .submit-button {
                padding: 10px 20px;
                background-color: #3667E9;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 700;

                &:hover {
                    background-color: #2a4eb8;
                }
            }
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 80%;
    width: 400px;

    h2 {
        margin-top: 0;
        color: #333;
        font-size: 24px;
        margin-bottom: 15px;
    }

    p {
        color: #666;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modal-button {
        margin-top: 20px;
        padding: 10px 30px;
        background-color: #3667E9;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;

        &:hover {
            background-color: #2a4eb8;
        }
    }
}