@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins-medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-thin";
  src: url("./assets/fonts/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins-light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

* {
  font-family: "Poppins-medium";
}

.bg-blue {
  background: #3667e9;
}

.form-control.is-invalid {
  padding: 14px !important;
  background-image: none !important;
}

.right-1{
  min-width: 300px;
  width: 56% ;

  max-width: 95%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
 }
