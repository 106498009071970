

  input[type="range"].sliderInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #3667E9;
    border-radius: 50%;
    cursor: pointer;
  }


.voice-recorder{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    .head{
        font-size: 32px;
        font-weight: 700;
    }

    .controller{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .time{
            font-size: 16px;
            font-weight: 500;
        }
        .icon{
            background-color: #3667E9;
            color: white;
            font-size: 49px;
            border-radius: 50%;
            padding: 41px;
            box-sizing: unset;
            border: 21px solid #ffffff;
            box-shadow: 0px 0px 1px 6px #3667E9;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .audio-display{
            background-color: #F0F0F0;
            border-radius: 6px;
            height: 80px;
            width: calc(100% - 40px);
            box-sizing: border-box;
            margin: 40px;
            display: flex;
            gap : 10px;
            justify-content: center;
            align-items: center;
            padding: 0px 12px;


            .icon-mini{
                color:#3667E9;
                background-color: #fff;
                border-radius: 50%;
                padding: 5px;
                box-sizing: unset;
                height: 34px;
                width: 34px;
                cursor: pointer;
            }
            .timer{
                width: 90px;
            }

            .slider{
                width: calc(100% - 100px);
                background-color: #fff;
                height: 6px;
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                

                .pad{
                    background-color: #3667E9;
                    position: absolute;
                    left: 0px;
                    top : 0px;
                    height: 100%;
                    // width: 50%;
                    border-radius: 4px;
                }
            }

            .sliderInput{
                width: calc(100% - 100px);

            }
            
            
        }
    }
 
    @media (max-width: 768px) {
        .head{
            font-size: 20px;
            font-weight: 600;
        }
        .controller {
            padding: 2px; 
            .icon {
                padding: 20px; 
                font-size: 32px;
            }
            .audio-display {
             margin:0px
            }
        }
    }
  
}
.footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;

    .btn, .footer-button{
        border-radius: 50%;
        background-color: #b4b4b481;
        color: #3667E9;
        cursor: pointer;
        padding: 8px;
        box-sizing: unset;
        font-size: 34px;
        border: none;
    }
}