.start-main .left{
    .client-name, .walkthrough{
        padding: 16px;
        background-color: #fff;
        border-radius: 8px;
        border-bottom: 20px;

        .head{
            font-weight: bold;
            border-bottom: 2px solid whitesmoke;
            padding-bottom: 12px;

        }

        .name{
            font-size: 20px;
            padding: 20px 10px;
            font-weight: 500;
            color: #191A23;
        }

        .steps{
            margin-top: 12px;
            .step{
                padding: 16px 12px;
                margin: 6px;
                border-radius: 4px;
                font-weight: 500;
                font-size: 16px;

                &.active{
                    background-color: #E3EBFF;
                }
            }
        }
    }
}