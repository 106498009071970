.main-section-container {
    height: calc(100vh - 108px);
    padding: 16px 0 0 0;
    background-color: #fff;
    overflow: auto;
    margin: 50px 0;
}

.main-section-wrap {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 20px;
}

.main-header h2 {
    font-size: 22px;
    margin: 0;
}

.main-header p {
    font-size: 14px;
}

.consultations-title {
    font-size: 20px;
}

.consultation-content {
    flex: 1;
}

.consultation-image {
    width: 100%;
    max-width: 60px;
}

.consultation-image img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.consultation-list {
    height: calc(100vh - 258px);
    overflow: auto;
}

.consultation-list li {
    border-bottom: 1px solid #ddd;
    padding-bottom: 14px;
}

.consultation-content p {
    font-size: 13px;
    margin-bottom: 0px;
}

.consultation-content p:last-child {
    margin-bottom: 0;
}

.consultation-content .time {
    font-weight: 600;
}

.next-btn {
    padding: 10px 25px;
}

.main-date-picker-wrap {
    position: absolute;
    top: 5px;
    right: 5px;
}

.main-date-picker .react-date-picker__inputGroup,
.main-date-picker .react-date-picker__clear-button {
    display: none;
}

.consultations-content h6 {
    font-weight: 600;
}

.consultations-content p {
    font-size: 14px;
}

.consultation-list-2 {
    height: calc(100vh - 360px);
}

.btn-list {
    max-width: 400px;
}

.btn-list button {
    margin-bottom: 15px;
}

.recording-box {
    height: 300px;
    background-color: #eee;
    box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.2);
    position: relative;
}

.recording-box-btn {
    border: 0;
    background-color: #ffffff;
    height: 60px;
    width: 60px;
    display: block;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 5px;
}

.animation-btn {
    animation: example 2s infinite;
}

.breadcrumb li {
    cursor: pointer;
    user-select: none;
    opacity: 0.9;
    font-size: 14px;
}

.breadcrumb li:hover {
    opacity: 1;
}

.breadcrumb li:last-child {
    opacity: 1;
    font-weight: 500;
}

@keyframes example {
    0%   {filter: drop-shadow(0px 2px 10px rgba(0, 600, 20, 0.2));}
    25%  {filter: drop-shadow(0px 2px 15px rgba(0, 600, 20, 0.3));}
    50%  {filter: drop-shadow(0px 2px 5px rgba(0, 600, 20, 0.3));}
    75%  {filter: drop-shadow(0px 2px 25px rgba(0, 600, 20, 0.363));}
    100% {filter: drop-shadow(0px 2px 3px rgba(0, 600, 20, 0.2));}
  }
    
.recording-box-btn span {
    padding: 10px;    
    border: 2px solid #004ca7;
    display: block;
    border-radius: 50%;
}

.danger-btn {
    background-color: #dc3545;
    height: 50px;
    width: 50px;
}

.danger-btn span {
    border-color: #fff;
    padding: 7px;
}

.danger-btn span svg {
    height: 20px;
    width: 20px;
}

.recording-btns {
    position: absolute;
    bottom: 20px;
    left: 0;
}

.recording-main-btn {
    height: 70px;
    width: 70px;
}

.complete-btn {
    border-color: #157347;
}

.tab-wraper .nav-item .nav-link {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    background-color: #ddd;
}

.tab-wraper .nav-item .nav-link.active {
    background-color: transparent;
    border-color: #000;
    border-bottom-color: #fff;
}

.tab-wraper .ql-container , .react-quill-show {
    height: calc(100vh - 420px) !important;
    overflow: auto;
}

.btn {
    background-color: #004ca7;
}


/* //////////// */
.audio-player-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 16px auto;
    width: 100%;
    max-width: 600px;
}

.audio-player-container audio {
    width: 100%;
    margin-top: 16px;
}

.audio-player-container .audio-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    cursor: pointer;
    opacity: 0.8;
    border-color: #000;
}

@media screen and (max-width: 991px) {
    .main-header {
        flex-wrap: wrap;
    }
    .breadcrumb {
        order: 5;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }
    .recording-box {
        height: 250px;
    }

    .tab-wraper .nav-item .nav-link {
        font-size: 20px;
    }

    .main-header > p {
        width: 100%;
        text-align: center !important;
    }
}

@media screen and (max-width: 767px) {
    .consultation-list {
        height: auto;
        max-height: 300px;
    }

    .main-section-container {
        height: calc(100vh - 60px);
        margin: 30px 0;
    }

    .consultation-image img {
        width: 55px;
        height: 55px;
    }

    body {
        font-size: 14px;
    }

}