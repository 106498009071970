.sidebar {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;

  .side {
    position: sticky;
    left: 0px;
    top: 0px;
    width: 300px;
    box-shadow: 3px 0px 12px whitesmoke;
    z-index: 2;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 20px 12px;
    border-right: 1px solid rgb(209, 209, 209);
    flex-direction: column;

    .logo {
      margin: -20px -12px 0px -12px;
      display: flex;
      justify-content: center;
      box-shadow: 1px 1px 1px #dfdfdf;

      img {
        height: 83px;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 50px;
      width: 100%;

      .link {
        border-radius: 4px;
        padding: 12px;
        display: flex;
        justify-content: left;
        color: black;
        gap: 13px;
        align-items: center;

        .icon {
          color: gray;
        }

        &.active {
          // color: rgb(26, 75, 238);
          background-color: #3666e913;
          .icon {
            color: rgb(0, 66, 207);
          }
        }
      }
    }
  }

  .container-sidebar {
    width: 100%;
    .header {
      height: 75px;
      box-shadow: 0px 2px 3px #bdbdbd;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 42px 25px;
      align-items: center;

      .page {
        font-size: 24px;
        font-weight: bold;
        color: #00227d;
      }
      .right-section {
        display: flex;
        align-items: center;
        gap: 20px;

        .admin-buttons {
          display: flex;
          gap: 8px;

          button {
            padding: 8px 12px;
            background-color: #3667e9;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
        }

        .profile {
          display: flex;
          gap: 12px;
          align-items: center;
          cursor: pointer;
          .username {
            font-size: 14px;
          }
          .profile-icon {
            padding: 8px;
            border-radius: 12px;
            background-color: #3666e917;
            color: darkblue;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .right-section .admin-buttons button {
    padding: 6px 6px;
    font-size: 14px;
    height: 2.5rem;
    font-size: 0.75rem;
  }
  .right-section {
    margin-left: 1rem;
  }
}

@media (max-width: 480px) {
  .right-section .admin-buttons button {
    padding: 4px 8px;
    font-size: 12px;
  }
}
