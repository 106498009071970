.start-main{
    display: flex;
    margin: auto;
    width: 100%;
    height: calc(100vh - 84px);
    background-color: #F5F6F6;
    padding: 10px;
    gap: 20px;
    justify-content :center;
    flex-wrap: wrap;
    overflow-y: scroll;
    

    .left{
        width: 33% ;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // height: 100%;
        max-width: 95%;

       .to-do, .unplanned{
        background-color: #fff;
        border-radius: 12px;
        padding: 0px 30px;
        width: 100%;
        height: calc(100% - 160px);
        overflow-x: scroll;
        // overflow-y: hidden;
        position: relative;
        // border: 1px solid rgb(223, 223, 223);
       .head{
        position: sticky;
        padding-top: 12px;
        top: 0px;
        background-color: #fff;
        z-index: 1;

        h3{
            color: rgb(0, 0, 0);
            font-size: 20px;
            font-weight: bold;
            border-bottom: 2px solid rgba(188, 188, 188, 0.581);
            padding-bottom: 10px;
        }

       .note{
        font-size: 12px;
        }
       }
        .list{
            margin-top: 12px;
            overflow-y: auto; /* Enables vertical scrolling */
            height: 60vh;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .item{
                border-radius: 4px;
                border: 1px solid rgb(241, 241, 241);
                padding: 8px;
                display: flex;
                justify-content: space-between;
                .seg1{
                    .name{
                        font-weight: 700;
                        color: rgb(22, 22, 22);
                    }
                    .date{
                        font-size: 12px;
                        color: rgb(169, 169, 169);
                        font-weight: 500;
                    }
                }

                .seg2{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;

                    .icon{
                        color: rgb(83, 83, 83);
                        border-radius: 4px;
                        border: 1px solid rgb(188, 188, 188);
                        cursor: pointer;
                    }
                    .time{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-size: 14px;
                        color: gray;
                    }
                }
            }
        }
       }

       .unplanned{
        height: 160px;
        overflow: hidden;
        
       
        .details{
            display: flex;
            gap: 20px;
            align-items: center;
            margin: 10px 8px;

         

            .input{
                border: 1px solid rgb(211, 211, 211);
                padding: 12px;
                border-radius: 8px;
                color: darkblue;
                width: calc(100% - 60px);
                &:focus-visible{
                    outline: none;
                    box-shadow: 0px 0px 12px rgb(173, 173, 231);
                }

                &::placeholder{
                    color: gray;
                }
            }

            @media (max-width: 1098px) {
                .input::placeholder {
                  font-size: 12px; /* Smaller font size for smaller screens */
                }

            
              }

            .mic{
                border-radius: 50%;
                background-color: rgba(180, 180, 180, 0.374);
                padding: 6px;
                height: 44px;
                width: 44px;
                cursor: pointer;
                color: #3667E9;
            }
        }


       }
    }
    .middle{
        // min-width: 400px;
        width: 33% ;
        max-width: 95%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        background-color: #fff;
        border-radius: 12px;
        padding: 10px;
       }
    
    .right {
        max-width: 95%;
        height: min-content;
        width: min-content;
        background-color: #fff;
            border-radius: 16px;
        &>div{
           margin: 0px !important; 
        }
       }
    
}

@media (max-width:980px) {
    .left{
        width: 90% !important;
        max-width: 100% !important;
    }
    .right , .middle{
        width: 50% !important;
        // max-width: 100% !important;
    }
    
}

@media (max-width: 640px) {
    .right {
      display: none; /* Hide on screens smaller than 1024px */
    }
  }