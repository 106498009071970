.main-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
  height: 100vh;
  overflow: auto;
  padding: 20px;
  background-image: url("../images/loginbg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100vw 70vw;
  position: relative;
  z-index: 1;
}

.main-auth-form {
  width: 378px;
  padding: 8px 20px;
  box-shadow: 0px 0px 15px #00000036;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: fit-content;
}

// .main-Microsoft-form {
//   width: 378px;
//   padding: 24px;
//   box-shadow: 0px 0px 15px #00000036;
//   border-radius: 12px;
//   display: flex;
//   justify-content: flex-start;
//   text-align: left;
//   flex-direction: column;
//   background-color: #fff;
// }

.auth-button {
  padding: 12px;
}

.message {
  color: gray;
  font-size: 12px;
  margin: 5px auto;
  &.flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
@media screen and (max-width: 767px) {
  .main-auth-form {
    padding: 30px 20px;
  }
}
